<article class="intro-container">
  <section class="intro-text-container">
    <h1 class="intro-title">
      {{ "content.home.intro.title" | translate }}
    </h1>
    <p class="intro-p">
      {{ "content.home.intro.p" | translate }}
    </p>
    <div class="download-buttons">
      <a
        href="https://play.google.com/store/apps/details?id=com.teemeeuser"
        tabIndex="0"
        ><img
          class="bn45"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
          alt="bn45"
      /></a>
      <a href="https://apps.apple.com/jo/app/teemee/id6448797827" tabIndex="0"
        ><img
          class="bn46"
          src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
          alt="bn45"
      /></a>
    </div>
  </section>
  <figure class="intro-shape">
    <img src="svgs/intro-shape.svg" alt="boy holding a laptop" />
  </figure>
</article>
