import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  constructor(private readonly router: Router) {}

  isFacebookActive = false;
  isInstagramActive = false;
  isXActive = false;

  navigateToEnjoyWithUs() {
    this.router.navigateByUrl('/enjoy-with-us');
  }

  navigateToBusinessOwners() {
    this.router.navigateByUrl('/business-owners');
  }
}
