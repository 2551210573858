<article class="enjoy-with-us-form-container">
  <section class="info-container">
    <h5 class="info-h5">{{ "enjoyWithUsForm.enjoyWithUs" | translate }}</h5>
    <h2 class="info-h2">{{ "enjoyWithUsForm.title" | translate }}</h2>
    <p class="info-p">
      {{ "enjoyWithUsForm.p" | translate }}
    </p>
  </section>
  <section class="form-container">
    <h2>{{ "enjoyWithUsForm.applyNow" | translate }} &#10024;</h2>
    <form [formGroup]="this.enjoyWithUsForm" (ngSubmit)="onSubmit()">
      <div class="control-container">
        <label for="fullName">{{
          "enjoyWithUsForm.fullName" | translate
        }}</label>
        <input
          type="fullName"
          name="fullName"
          [formControl]="this.enjoyWithUsForm.controls['fullName']"
          id="fullName"
          [placeholder]="'enjoyWithUsForm.fullName' | translate"
        />
      </div>
      <div class="control-container">
        <label for="phoneNumber">{{
          "enjoyWithUsForm.phoneNumber" | translate
        }}</label>
        <input
          type="phoneNumber"
          inputmode="numeric"
          oninput="this.value = this.value.replace(/\D+/g, '')"
          name="phoneNumber"
          [formControl]="this.enjoyWithUsForm.controls['phoneNumber']"
          id="phoneNumber"
          [placeholder]="'enjoyWithUsForm.phoneNumber' | translate"
        />
      </div>
      <div class="control-container">
        <select
          name="activities"
          id="activities"
          (change)="selectActivity($event)"
        >
          <option value="-1" disabled="true" [selected]="true">
            {{ "enjoyWithUsForm.activityName" | translate }}
          </option>
          @for (activity of activities; track $index) {
          <option [value]="activity._id">
            {{ activity["user.name" | translate] }}
          </option>
          }
        </select>
      </div>
      <button [disabled]="!this.enjoyWithUsForm.valid" type="submit">
        {{ "enjoyWithUsForm.sendButton" | translate }}
      </button>
    </form>
  </section>
</article>
