import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-download-app',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './download-app.component.html',
  styleUrl: './download-app.component.scss'
})
export class DownloadAppComponent {

}
