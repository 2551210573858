import {
  ApplicationRef,
  Component,
  ComponentRef,
  createComponent,
  Injectable,
  Type,
} from '@angular/core';
import { ComponentClass } from './component.class';
import { Subject } from 'rxjs';
import { DynamicObjectI } from '../../interfaces/dynamic-object.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private readonly appRef: ApplicationRef) {}
  dialogData: Subject<any> = new Subject();

  dialogComponent!: ComponentRef<Component>;

  public openDialog(
    component: Type<ComponentClass>,
    data: DynamicObjectI = {}
  ) {
    const parentElement = document.getElementById('dialogs')!;
    const hostElement = document.createElement('div');
    parentElement.appendChild(hostElement);

    const environmentInjector = this.appRef.injector;
    this.dialogComponent = createComponent(component, {
      hostElement,
      environmentInjector,
    });

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key];
        this.dialogComponent.setInput(key, element);
      }
    }

    this.appRef.attachView(this.dialogComponent.hostView);
    this.dialogComponent.changeDetectorRef.detectChanges();
  }

  public closeDialog() {
    if (!this.dialogComponent) return;
    this.appRef.detachView(this.dialogComponent.hostView);
    this.dialogComponent.destroy();
  }
}
