<div class="background">
    <app-header></app-header>
    <hr />
    <app-business-owners-intro></app-business-owners-intro>
    <app-join-teemee></app-join-teemee>
    <br><br>
    <app-subscribe-form></app-subscribe-form>
    <br><br>
</div>

<app-footer></app-footer>