import { Component } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { BusinessOwnersIntroComponent } from '../business-owners-intro/business-owners-intro.component';
import { JoinTeemeeComponent } from '../join-teemee/join-teemee.component';
import { SubscribeFormComponent } from '../subscribe-form/subscribe-form.component';

@Component({
  selector: 'app-business-owners',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    BusinessOwnersIntroComponent,
    JoinTeemeeComponent,
    SubscribeFormComponent
  ],
  templateUrl: './business-owners.component.html',
  styleUrl: './business-owners.component.scss',
})
export class BusinessOwnersComponent {}
