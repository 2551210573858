import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../core/services/api/api.service';
import { ActivityI } from '../../core/interfaces/activity.interface';
import { finalize, Subject, takeUntil } from 'rxjs';
import { NgFor } from '@angular/common';
import { ToastService } from '../../core/services/toast/toast.service';
import { DelegatedUIErrorI } from '../../core/shared/interfaces/delegated-ui-error.interface';

@Component({
  selector: 'app-enjoy-with-us-form',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, NgFor],
  templateUrl: './enjoy-with-us-form.component.html',
  styleUrl: './enjoy-with-us-form.component.scss',
})
export class EnjoyWithUsFormComponent implements OnInit, OnDestroy {
  constructor(
    private readonly fb: NonNullableFormBuilder,
    private readonly toastService: ToastService,

    private readonly apiService: ApiService
  ) {}

  enjoyWithUsForm = this.fb.group({
    fullName: [
      '',
      [Validators.required, Validators.min(3), Validators.max(320)],
    ],
    phoneNumber: ['', [Validators.required]],
  });
  private readonly destroy$ = new Subject<void>();
  activities: ActivityI[] = [];
  selectedActivity?: ActivityI;

  ngOnInit(): void {
    this.apiService.get<ActivityI[]>('activities').subscribe({
      next: (activitiesResponse) => {
        const { data } = activitiesResponse;
        this.activities = data;
      },
    });
  }

  selectActivity(item: any) {
    this.selectedActivity = item.target.value;
  }

  onSubmit() {
    this.apiService
      .post<string>('attendees/' + this.selectedActivity, {
        ...this.enjoyWithUsForm.value,
        activityName: undefined,
      })
      .pipe(
        finalize(() => {
          this.enjoyWithUsForm.reset();
          this.toastService.showSuccess('Done!');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (loginResponse) => {},
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
