import { Component } from '@angular/core';
import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { DiscoverComponent } from './views/discover/discover.component';
import { AboutComponent } from './views/about/about.component';
import { GetTeemeeComponent } from './views/get-teemee/get-teemee.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { IntroComponent } from './views/intro/intro.component';
import { WhyTeemeeComponent } from './views/why-teemee/why-teemee.component';
import { DownloadAppComponent } from './views/download-app/download-app.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'TeeMee';
}
