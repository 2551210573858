<footer>
  <section class="items-container">
    <div class="logo-item">
      <div class="logo-container">
        <figure><img src="svgs/logo.svg" alt="logo" /></figure>
      </div>
      <p>
        {{ "footer.p" | translate }}
      </p>
    </div>
    <nav class="links-item">
      <h3>{{ "footer.sections" | translate }}</h3>
      <li class="link">
        <a href="#home">{{ "footer.home" | translate }}</a>
      </li>
      <li class="link">
        <a href="#about-teemee">{{ "footer.aboutTeemee" | translate }}</a>
      </li>
      <li class="link" (click)="this.navigateToEnjoyWithUs()">
        <a>{{ "footer.enjoyWithUs" | translate }}</a>
      </li>
      <li class="link" (click)="this.navigateToBusinessOwners()">
        <a>{{ "footer.businessOwners" | translate }}</a>
      </li>
      <li class="link">
        <a href="#why-teemee">{{ "footer.whyTeemee" | translate }}</a>
      </li>
      <li class="link">
        <a href="#contact-us">{{ "footer.contactUs" | translate }}</a>
      </li>
    </nav>
    <div class="discover">
      <h3>{{ "footer.discoverOurApp" | translate }}</h3>
      <div class="download-buttons">
        <a
          href="https://play.google.com/store/apps/details?id=com.teemeeuser"
          tabIndex="0"
          ><img
            class="bn45"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
            alt="bn45"
        /></a>
        <a href="https://apps.apple.com/jo/app/teemee/id6448797827" tabIndex="0"
          ><img
            class="bn46"
            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
            alt="bn45"
        /></a>
      </div>
    </div>
    <div class="social-item">
      <div class="spacer"></div>
      <div class="platforms">
        <figure
          (mouseenter)="this.isFacebookActive = true"
          (mouseleave)="this.isFacebookActive = false"
        >
          <a target="_blank" href="https://www.facebook.com/TeeMeeapp">
            <img
              [src]="
                'svgs/' +
                (!this.isFacebookActive ? 'non-' : '') +
                'active-facebook.svg'
              "
              alt="facebook"
            />
          </a>
        </figure>
        <figure
          (mouseenter)="this.isInstagramActive = true"
          (mouseleave)="this.isInstagramActive = false"
        >
          <a target="_blank" href="https://www.instagram.com/teemeeapp/">
            <img
              [src]="
                'svgs/' +
                (!this.isInstagramActive ? 'non-' : '') +
                'active-instagram.svg'
              "
              alt="instagram"
          /></a>
        </figure>
        <figure
          (mouseenter)="this.isXActive = true"
          (mouseleave)="this.isXActive = false"
        >
          <a target="_blank" href="https://www.x.com/teemeeapp/">
            <img
              [src]="'svgs/' + (!this.isXActive ? 'non-' : '') + 'active-x.svg'"
              alt="x"
          /></a>
        </figure>
      </div>
    </div>
  </section>
  <section class="ending">
    <hr />
    <p>{{ "footer.copyRight" | translate }} © Teemee 2024</p>
  </section>
</footer>
