<article id="contact-us" class="contact-us-container">
  <section class="info-container">
    <h5 class="info-h5">{{ "content.home.contactUs.header" | translate }}</h5>
    <h2 class="info-h2">{{ "content.home.contactUs.title" | translate }}</h2>
    <p class="info-p">
      {{ "content.home.contactUs.p" | translate }}
    </p>
  </section>
  <section class="form-container">
    <h2>{{ "content.home.contactUs.header" | translate }} &#10024;</h2>
    <form [formGroup]="this.contactUsForm" (ngSubmit)="onSubmit()">
      <div class="name-container">
        <div class="control-container">
          <label for="firstName">{{
            "contactUsForm.firstName" | translate
          }}</label>
          <input
            type="text"
            name="firstName"
            [formControl]="this.contactUsForm.controls['firstName']"
            id="firstName"
            [placeholder]="'contactUsForm.firstName' | translate"
          />
        </div>
        <div class="control-container">
          <label for="lastName">{{
            "contactUsForm.lastName" | translate
          }}</label>
          <input
            type="text"
            name="lastName"
            [formControl]="this.contactUsForm.controls['lastName']"
            id="lastName"
            [placeholder]="'contactUsForm.lastName' | translate"
          />
        </div>
      </div>
      <div class="control-container">
        <label for="email">{{ "contactUsForm.email" | translate }}</label>
        <input
          type="email"
          name="email"
          [formControl]="this.contactUsForm.controls['email']"
          id="email"
          [placeholder]="'contactUsForm.email' | translate"
        />
      </div>
      <div class="control-container">
        <label for="phoneNumber">{{
          "contactUsForm.phoneNumber" | translate
        }}</label>
        <input
          type="phoneNumber"
          inputmode="numeric"
          name="phoneNumber"
          oninput="this.value = this.value.replace(/\D+/g, '')"
          [formControl]="this.contactUsForm.controls['phoneNumber']"
          id="phoneNumber"
          [placeholder]="'contactUsForm.phoneNumber' | translate"
        />
      </div>
      <div class="control-container">
        <label for="message">{{ "contactUsForm.message" | translate }}</label>
        <textarea
          name="message"
          [formControl]="this.contactUsForm.controls['message']"
          id="message"
          [placeholder]="'contactUsForm.message' | translate"
        ></textarea>
      </div>
      <button [disabled]="!this.contactUsForm.valid" type="submit">
        {{ "contactUsForm.sendButton" | translate }}
      </button>
    </form>
  </section>
</article>
