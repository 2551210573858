<article class="contact-us-container">
  <section class="info-container">
    <h5 class="info-h5">{{ "subscribeForm.subscribe" | translate }}</h5>
    <h2 class="info-h2">{{ "subscribeForm.title" | translate }}</h2>
    <p class="info-p">
      {{ "subscribeForm.p" | translate }}
    </p>
  </section>
  <section class="form-container">
    <h2>{{ "subscribeForm.subscriptionForm" | translate }} &#10024;</h2>
    <form [formGroup]="this.subscribeForm" (ngSubmit)="onSubmit()">
      <div class="control-container">
        <label for="fullName">{{ "subscribeForm.fullName" | translate }}</label>
        <input
          type="fullName"
          name="fullName"
          [formControl]="this.subscribeForm.controls['fullName']"
          id="fullName"
          [placeholder]="'subscribeForm.fullName' | translate"
        />
      </div>
      <div class="control-container">
        <label for="businessName">{{
          "subscribeForm.businessName" | translate
        }}</label>
        <input
          type="businessName"
          name="businessName"
          [formControl]="this.subscribeForm.controls['businessName']"
          id="businessName"
          [placeholder]="'subscribeForm.businessName' | translate"
        />
      </div>
      <div class="control-container">
        <label for="email">{{ "subscribeForm.email" | translate }}</label>
        <input
          type="email"
          name="email"
          [formControl]="this.subscribeForm.controls['email']"
          id="email"
          [placeholder]="'subscribeForm.email' | translate"
        />
      </div>
      <div class="control-container">
        <label for="phoneNumber">{{
          "subscribeForm.phoneNumber" | translate
        }}</label>
        <input
          type="phoneNumber"
          inputmode="numeric"
          name="phoneNumber"
          oninput="this.value = this.value.replace(/\D+/g, '')"
          [formControl]="this.subscribeForm.controls['phoneNumber']"
          id="phoneNumber"
          [placeholder]="'subscribeForm.phoneNumber' | translate"
        />
      </div>
      <button [disabled]="!this.subscribeForm.valid" type="submit">
        {{ "subscribeForm.sendButton" | translate }}
      </button>
    </form>
  </section>
</article>
