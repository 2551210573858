<article class="discover-container">
  <section class="discover-text-container">
    <h5 class="discover-h5">
      {{ "content.home.discover.header" | translate }}
    </h5>
    <h2 class="discover-h2">{{ "content.home.discover.title" | translate }}</h2>
    <p class="discover-p">
      {{ "content.home.discover.p" | translate }}
    </p>
    <!-- <button class="download-button">{{ "common.download" | translate }}</button> -->
  </section>
  <figure class="discover-shape">
    <img #img src="svgs/discover-shape.svg" alt="boy holding a laptop" />
    <video
      [width]="img.offsetWidth - 20"
      [height]="img.offsetHeight - 20"
      [muted]="'muted'"
      loop
      autoplay
      playsinline
      playsinline
    >
      <source src="videos/discover.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </figure>
</article>
