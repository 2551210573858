<article class="join-teemee-container">
    <figure class="join-teemee-shape">
      <img src="svgs/join-teemee-shape.svg" alt="boy holding a laptop" />
    </figure>
    <section class="join-teemee-text-container">
      <h5 class="join-teemee-h5">{{ "content.businessOwners.joinTeemee.header" | translate }}</h5>
      <h2 class="join-teemee-h2">
        {{ "content.businessOwners.joinTeemee.title-part1" | translate }}
      <span style="color: rgba(82, 146, 27, 1)">
        {{ "content.businessOwners.joinTeemee.title-part2" | translate }}
      </span>
      {{ "content.businessOwners.joinTeemee.title-part3" | translate }}
      </h2>
      <p class="join-teemee-p">
        {{ "content.businessOwners.joinTeemee.p" | translate }}
      </p>
      <!-- <button class="join-teemee-button" (click)="this.navigateToContactUs()">
        {{ "common.joinNow" | translate }}
      </button> -->
    </section>
  </article>
  