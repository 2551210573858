<article class="why-teemee-container" id="why-teemee">
  <figure class="why-teemee-shape">
    <img src="svgs/why-teemee-shape.svg" alt="boy holding a laptop" />
  </figure>
  <section class="why-teemee-text-container">
    <h5 class="why-teemee-h5">{{ "content.home.whyTeemee.header" | translate }}</h5>
    <h2 class="why-teemee-h2">
      {{ "content.home.whyTeemee.title-part1" | translate }}<span style="color: rgba(82, 146, 27, 1)"
        >{{ "content.home.whyTeemee.title-part2" | translate }}</span
      >
    </h2>
    <p class="why-teemee-p">
      {{ "content.home.whyTeemee.p" | translate }}
    </p>
    <button class="join-now-button" (click)="this.navigateToBusinessOwners()">
      {{ "common.joinNow" | translate }}
    </button>
  </section>
</article>
