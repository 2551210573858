import { Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { BusinessOwnersComponent } from './views/business-owners/business-owners.component';
import { SubscribeComponent } from './views/subscribe/subscribe.component';
import { EnjoyWithUsComponent } from './views/enjoy-with-us/enjoy-with-us.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'business-owners', component: BusinessOwnersComponent },
  { path: 'subscribe', component: SubscribeComponent },
  { path: 'enjoy-with-us', component: EnjoyWithUsComponent },
];
