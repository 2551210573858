import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-get-teemee',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './get-teemee.component.html',
  styleUrl: './get-teemee.component.scss'
})
export class GetTeemeeComponent {

}
