import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-join-teemee',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './join-teemee.component.html',
  styleUrl: './join-teemee.component.scss'
})
export class JoinTeemeeComponent {
  constructor(private readonly router: Router) {}

  navigateToContactUs() {
    this.router.navigateByUrl('/contact-us');
  }
}
