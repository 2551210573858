<div class="background" id="home">
  <app-header></app-header>
  <hr />
  <app-intro></app-intro>
  <app-about></app-about>
</div>
<app-discover></app-discover>

<app-get-teemee></app-get-teemee>

<app-why-teemee></app-why-teemee><br /><br />
<app-contact-us></app-contact-us>
<app-download-app></app-download-app>
<app-footer></app-footer>
