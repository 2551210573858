import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-why-teemee',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './why-teemee.component.html',
  styleUrl: './why-teemee.component.scss',
})
export class WhyTeemeeComponent {
  constructor(private readonly router: Router) {}

  navigateToBusinessOwners() {
    this.router.navigateByUrl('/business-owners');
  }
}
