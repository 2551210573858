<article class="download-app-container">
  <section class="title">
    <h2>{{ "common.download" | translate }}</h2>
    <h6>{{ "common.downloadToDiscover" | translate }}</h6>
  </section>
  <section class="icons">
    <div class="download-buttons">
      <a href="https://play.google.com/store/apps/details?id=com.teemeeuser" tabIndex="0"
        ><img
          class="bn45"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
          alt="bn45" /></a
      ><a href="https://apps.apple.com/jo/app/teemee/id6448797827" tabIndex="0"
        ><img
          class="bn46"
          src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
          alt="bn45"
      /></a>
    </div>
  </section>
</article>
