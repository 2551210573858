import { Component, OnDestroy } from '@angular/core';
import {
  ReactiveFormsModule,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../core/services/api/api.service';
import { finalize, Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../core/services/toast/toast.service';
import { DelegatedUIErrorI } from '../../core/shared/interfaces/delegated-ui-error.interface';

@Component({
  selector: 'app-subscribe-form',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  templateUrl: './subscribe-form.component.html',
  styleUrl: './subscribe-form.component.scss',
})
export class SubscribeFormComponent implements OnDestroy {
  constructor(
    private readonly fb: NonNullableFormBuilder,
    private readonly toastService: ToastService,

    private readonly apiService: ApiService
  ) {}

  private readonly destroy$ = new Subject<void>();

  subscribeForm = this.fb.group({
    fullName: [
      '',
      [Validators.required, Validators.min(3), Validators.max(320)],
    ],
    businessName: [
      '',
      [Validators.required, Validators.min(3), Validators.max(320)],
    ],
    email: [
      '',
      [
        Validators.required,
        Validators.min(3),
        Validators.max(320),
        Validators.email,
      ],
    ],
    phoneNumber: ['', [Validators.required]],
  });

  onSubmit() {
    this.apiService
      .post<string>('mails/subscribe', this.subscribeForm.value)
      .pipe(
        finalize(() => {
          this.subscribeForm.reset();
          this.toastService.showSuccess('Done!');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (loginResponse) => {},
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
