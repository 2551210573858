import { Component } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { EnjoyWithUsFormComponent } from '../enjoy-with-us-form/enjoy-with-us-form.component';

@Component({
  selector: 'app-enjoy-with-us',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, EnjoyWithUsFormComponent],
  templateUrl: './enjoy-with-us.component.html',
  styleUrl: './enjoy-with-us.component.scss',
})
export class EnjoyWithUsComponent {}
