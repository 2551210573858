<article class="about-container" id="about-teemee">
  <figure class="about-shape">
    <img src="svgs/about-shape.svg" alt="boy holding a laptop" />
  </figure>
  <section class="about-text-container">
    <h5 class="about-h5">{{ "content.home.aboutTeemee.header" | translate }}</h5>
    <h2 class="about-h2">{{ "content.home.aboutTeemee.title" | translate }}</h2>
    <p class="about-p">
      {{ "content.home.aboutTeemee.p" | translate }}
    </p>
    <!-- <button class="download-button">{{ "common.download" | translate }}</button> -->
  </section>
</article>
