<article class="business-owners-intro-container">
  <section class="business-owners-intro-text-container">
    <h2 class="business-owners-intro-h2">
      {{ "content.businessOwners.intro.title-part1" | translate }}
      <span style="color: rgba(82, 146, 27, 1)">
        {{ "content.businessOwners.intro.title-part2" | translate }}
      </span>
      {{ "content.businessOwners.intro.title-part3" | translate }}
    </h2>
    <p class="business-owners-intro-p">
      {{ "content.businessOwners.intro.p" | translate }}
    </p>
    <!-- <button class="contact-us-button">{{ "common.contactUs" | translate }}</button> -->
  </section>
  <figure class="business-owners-intro-shape">
    <img #img src="svgs/discover-shape.svg" alt="boy holding a laptop" />
    <video
      [width]="img.offsetWidth - 20"
      [height]="img.offsetHeight - 20"
      [muted]="'muted'"
      loop
      autoplay
      playsinline
    >
      <source src="videos/business-owners.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </figure>
</article>
