import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideAnimations } from '@angular/platform-browser/animations';
import { authenticationInterceptor } from './core/interceptors/authentication/authentication.interceptor';
import { requestCountInterceptor } from './core/interceptors/request-count/request-count.interceptor';
import { errorsInterceptor } from './core/interceptors/errors/errors.interceptor';
import {
  provideRouter,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { languageInterceptor } from './core/interceptors/language/language.interceptor';
import { provideToastr } from 'ngx-toastr';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/i18n/', '.json');
}

export const provideTranslateModuleOptions = (): TranslateModuleConfig => ({
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
  defaultLanguage: 'en',
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withFetch(),
      withInterceptors([
        requestCountInterceptor,
        errorsInterceptor,
        languageInterceptor,
        authenticationInterceptor,
      ])
    ),
    provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      withInMemoryScrolling({ scrollPositionRestoration: 'top' })
    ),
    provideClientHydration(),
    provideAnimations(),
    importProvidersFrom(
      TranslateModule.forRoot(provideTranslateModuleOptions())
    ),
    provideToastr({ countDuplicates: true, preventDuplicates: true }),
  ],
};
