<article class="get-teemee-container">
  <section class="get-teemee-text-container">
    <h5 class="get-teemee-h5">{{ "content.home.getTeemee.header" | translate }}</h5>
    <h2 class="get-teemee-h2">
      <span style="color: rgba(82, 146, 27, 1)">{{ "content.home.getTeemee.title-part1" | translate }}</span>
      {{ "content.home.getTeemee.title-part2" | translate }}
    </h2>
    <p class="get-teemee-p">
      {{ "content.home.getTeemee.p" | translate }}
    </p>
  </section>
  <figure class="get-teemee-shape">
    <img src="svgs/get-teemee-shape.svg" alt="boy holding a laptop" />
  </figure>
</article>
