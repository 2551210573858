import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BackendResponseI } from './interfaces/backend-response.interface';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private API = 'https://be.teemeeapp.com/dev/api/';
  constructor(private readonly http: HttpClient) {}

  get = <T>(url: string, params?: any): Observable<BackendResponseI<T>> => {
    let httpParams = new HttpParams();
    for (const property in params) {
      httpParams = httpParams.set(property, params[property]);
    }

    return this.http.get<BackendResponseI<T>>(`${this.API}${url}`, {
      params: httpParams,
    });
  };

  post = <T>(url: string, body: any): Observable<BackendResponseI<T>> => {
    return this.http.post<BackendResponseI<T>>(`${this.API}${url}`, body);
  };

  patch = <T>(url: string, body?: any): Observable<BackendResponseI<T>> => {
    return this.http.patch<BackendResponseI<T>>(`${this.API}${url}`, body);
  };

  put = <T>(url: string, body?: any): Observable<BackendResponseI<T>> => {
    return this.http.put<BackendResponseI<T>>(`${this.API}${url}`, body);
  };

  delete = <T>(url: string, params?: any): Observable<BackendResponseI<T>> => {
    let httpParams = new HttpParams();
    for (const property in params) {
      httpParams = httpParams.set(property, params[property]);
    }
    return this.http.delete<BackendResponseI<T>>(`${this.API}${url}`, {
      params: httpParams,
    });
  };
}
