import { Component } from '@angular/core';
import { AboutComponent } from '../about/about.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { DiscoverComponent } from '../discover/discover.component';
import { DownloadAppComponent } from '../download-app/download-app.component';
import { FooterComponent } from '../footer/footer.component';
import { GetTeemeeComponent } from '../get-teemee/get-teemee.component';
import { HeaderComponent } from '../header/header.component';
import { IntroComponent } from '../intro/intro.component';
import { WhyTeemeeComponent } from '../why-teemee/why-teemee.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeaderComponent,
    IntroComponent,
    FooterComponent,
    DiscoverComponent,
    AboutComponent,
    GetTeemeeComponent,
    WhyTeemeeComponent,
    ContactUsComponent,
    DownloadAppComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {}
