import { Component, HostListener } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '../../core/services/translate/translation.service';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [TranslateModule, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isMobileMenuOpen = false;

  constructor(
    private readonly translationService: TranslationService,
    private readonly router: Router
  ) {}

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event & { target: { innerWidth: number } }) {
    const width = event.target?.innerWidth;

    if (width > 840) this.isMobileMenuOpen = false;
  }

  changeLanguage() {
    this.translationService.changeLanguage();
  }

  navigateToEnjoyWithUs() {
    this.router.navigateByUrl('/enjoy-with-us');
  }

  navigateToBusinessOwners() {
    this.router.navigateByUrl('/business-owners');
  }
}
